import React from "react";
import PropTypes from "prop-types";
import {
  formatDate,
  PanelGrid,
  Panel,
  Icon,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import { Translations } from "../translations";
import StatementSummary from "./StatementSummary";
import PaperlessPreferences from "./PaperlessPreferences";
import DocumentButton from "../DocumentButton";

const AccountSummary = ({ policy, account, paperless, isInPendingCancel }) => {
  const effectiveDate = Translations.formatString(
    Translations.account_summary.policy_list.effective_date_detail,
    formatDate(policy?.effective_date, "MM/DD/YYYY"),
    formatDate(policy?.expiration_date, "MM/DD/YYYY")
  );

  const policyStatus =
    policy?.status && Translations.policy_statuses[policy.status];

  const policyPayor = policy?.billing?.payor
    ? Translations.payor_types[policy.billing.payor]
    : policy?.section_group && policy?.section_group === "agency_bill"
    ? "Agent"
    : "Other";

  const showAutoIdCardsButton =
    policy?.auto_id_link && policy?.status !== "expired";

  const paperlessAlerts =
    paperless?.alertsData?.filter(_ => _.number === account.number) || [];

  const hasEligiblePaperlessAlerts =
    paperlessAlerts.length &&
    (paperlessAlerts[0]?.paperless_bill?.eligibility === "updatable" ||
      paperlessAlerts[0]?.policy_email_alert?.eligibility === "updatable" ||
      paperlessAlerts[0]?.bill_email_alert?.eligibility === "updatable");

  return (
    <PanelGrid sizes={{ sm: 1, md: 1, lg: 2, xl: 2 }}>
      <Panel shadow title="Statement Summary">
        <StatementSummary
          account={account}
          policy={policy}
          isLegacyInPendingCancel={
            isInPendingCancel && account?.origin === "ufg_core"
          }
        />
      </Panel>
      <Panel shadow title="Manage Paperless Preferences">
        {!paperless ||
        (paperless?.isLoading && !paperless.alertsData.length) ? (
          <LoadingIndicator />
        ) : hasEligiblePaperlessAlerts ? (
          <PaperlessPreferences account={account} />
        ) : (
          <p className="panel-row">No paperless options available.</p>
        )}
      </Panel>
      <Panel shadow title="Account Summary">
        <p className="panel-row">
          <span>Account Number</span>
          <span>{account?.number || ""}</span>
        </p>
        <p className="panel-row">
          <span>Policy Number</span>
          <span>{policy?.number || ""}</span>
        </p>
        <p className="panel-row">
          <span>Effective Date</span>
          <span>{effectiveDate}</span>
        </p>
        <p className="panel-row">
          <span>Policy Status</span>
          <span>{policyStatus}</span>
        </p>
        <p className="panel-row">
          <span>Named Insured</span>
          <span>{policy?.name || ""}</span>
        </p>
        <p className="panel-row">
          <span>Payor</span>
          <span data-js-id="payor-type">{policyPayor}</span>
        </p>
      </Panel>
      <Panel noBorder>
        <div>
          {showAutoIdCardsButton && (
            <DocumentButton
              pdfUri={policy.auto_id_link}
              filename="AutoId_Cards.pdf"
            >
              <Icon icon="farCarAlt" size="3x" />
              <div>Auto ID Cards</div>
            </DocumentButton>
          )}
          {policy?.rx_card_link && (
            <DocumentButton pdfUri={policy.rx_card_link} filename="rxcard.pdf">
              <Icon icon="farFilePrescription" size="3x" />
              <div>Rx Card</div>
            </DocumentButton>
          )}
        </div>
      </Panel>
    </PanelGrid>
  );
};

AccountSummary.propTypes = {
  policy: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  paperless: PropTypes.object.isRequired,
  isInPendingCancel: PropTypes.bool.isRequired
};

export default AccountSummary;
