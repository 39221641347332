import apiFactory from "../api";
import qs from "query-string";
import { formatDate } from "@ufginsurance/ui-kit";
// Developers can override this with an env.local file
const baseUIUrl = window.env.REACT_APP_BASEURL;
const baseUrl = window.env.REACT_APP_API_BASEURL;
//M4
const policyholderEapiBaseUrl =
  window.env.REACT_APP_POLICYHOLDER_EAPI_MULE_4_URI;
const policyAccountsEapiBaseUrl =
  window.env.REACT_APP_POLICYHOLDER_ACCOUNTS_EAPI_MULE_4_URI;
const policyholderAdminEapiBaseUrl =
  window.env.REACT_APP_POLICYHOLDER_ADMIN_EAPI_MULE_4_URI;

const api = apiFactory(baseUrl);
//M4
const policyholderEapi = apiFactory(`${baseUrl}${policyholderEapiBaseUrl}`);
const profileEapi = apiFactory(`${baseUrl}${policyholderEapiBaseUrl}`);
const billingEapi = apiFactory(`${baseUrl}${policyholderEapiBaseUrl}`);
const accountsEapi = apiFactory(`${baseUrl}${policyAccountsEapiBaseUrl}`);
const policiesEapi = apiFactory(`${baseUrl}${policyAccountsEapiBaseUrl}`);
const adminEapi = apiFactory(`${baseUrl}${policyholderAdminEapiBaseUrl}`);

///
/// pdfs
/// -----

export const getPdf = (url, options = {}) =>
  api.get(url, { ...options, responseType: "blob" });

export const fetchPdfs = (url, formList) =>
  api.post(url, formList, {
    "Content-Type": "application/json",
    responseType: "blob"
  });

///
/// general
/// -----

export const getQuickLinks = userId =>
  policyholderEapi.get(`/quick-links?user_id=${userId}`);

export const getHelpDeskInfo = () => policyholderEapi.get(`/help-desk`);

///
/// account
/// -----

export const fetchBillingActivityAccounts = () => accountsEapi.get("/accounts");

export const fetchClaimsAccountsData = () => accountsEapi.get("/accounts");

export const getBillingStatements = () => accountsEapi.get("/accounts/bills");

export const getAccountsWithAutoPolicies = () =>
  accountsEapi.get("/accounts/auto-id-cards");

export const getAccountSummary = shouldRevalidate => {
  return accountsEapi.get(
    "/accounts/summary",
    shouldRevalidate && {
      headers: { "Cache-Control": "must-revalidate" }
    }
  );
};

//{account_id}/bill

export const fetchOpenClaimsData = (number, status) =>
  accountsEapi.get(`/accounts/${number}/claims?status=${status}`);

export const fetchHistoryClaimsData = (number, status) =>
  accountsEapi.get(`/accounts/${number}/claims?status=${status}`);

//{{account_id}/policies

export const submitAddAccount = payload =>
  accountsEapi.post("/accounts/registration", payload);

export const patchAcknowledgePaperlessTerms = number =>
  accountsEapi.patch(
    `/accounts/${number}/acknowledgements?acknowledge_type=has_reviewed_paperless_terms`
  );

export const submitAccountReregistrationConfirmation = payload =>
  accountsEapi.post("/accounts/registration/complete", payload);

export const fetchBillingAndAlertsAccounts = () =>
  accountsEapi.get("/accounts/alerts");

export const submitBillingAndAlerts = payload =>
  accountsEapi.post("/accounts/alerts", payload);

export const fetchGuidewireConversions = () =>
  accountsEapi.get("/accounts/gw-conversions");

export const postGWConfirmation = () =>
  accountsEapi.post("/accounts/gw-conversions", JSON.stringify(""), {
    headers: { "Content-Type": "application/json" }
  });

export const fetchCompanyNotifications = () =>
  adminEapi.get("/admin/notifications");

export const fetchMyAgent = () => policyholderEapi.get(`/agencies`);

///
/// billing
/// -----

export const fetchAutomaticPaymentAccounts = () =>
  billingEapi.get("/billing/automatic-payments");

export const fetchPaymentVendorRoute = (accountNumber, returnUrl) =>
  billingEapi.get(
    `/billing/automatic-payments/enrollment/${accountNumber}?cancellation_url=${
      returnUrl || baseUIUrl
    }`
  );

export const fetchInstallmentScheduleAccounts = () =>
  billingEapi.get("/billing/installment-schedules");

export const fetchInstallmentScheduleAccountDetails = accountNumber =>
  billingEapi.get(`/billing/installment-schedules/${accountNumber}`);

export const fetchBillingActivityDetails = (
  accountNumber,
  activity_date_from,
  activity_date_to
) => {
  let queryString;
  if (activity_date_from && activity_date_to) {
    queryString = qs.stringify({
      activity_date_from: formatDate(activity_date_from, "YYYY-MM-DD"),
      activity_date_to: formatDate(activity_date_to, "YYYY-MM-DD")
    });
    return billingEapi.get(`billing/activity/${accountNumber}?${queryString}`);
  }
  return policyholderEapi.get(`billing/activity/${accountNumber}`);
};

export const fetchPolicyBalanceRemainingDetails = accountNumber =>
  billingEapi.get(`/billing/activity/${accountNumber}/unpaid-policy`);

///
/// policies
/// -----

export const fetchMyPoliciesList = () => policiesEapi.get("/policies");

export const fetchPolicyActivity = policyNumber =>
  policiesEapi.get(`/policies/${policyNumber}/activity`);

export const fetchPolicyDecs = (policyNumber, company_branch, effective_date) =>
  policiesEapi.get(
    `/policies/${policyNumber}/coverage-options?effective_date=${effective_date}&company_branch=${company_branch}`
  );

export const fetchPolicyForms = (policyNumber, coverageOption, paramsString) =>
  policiesEapi.get(
    `/policies/${policyNumber}/coverage-forms/${coverageOption}${paramsString}`
  );

export const submitNickname = (policyNumber, payload) =>
  policiesEapi.post(`/policies/${policyNumber}/nickname`, payload);

export const fetchCancellationNotice = policyNumber =>
  policiesEapi.get(`/policies/${policyNumber}/cancellation-notice`, {
    responseType: "blob"
  });

///
/// profile
/// -----

export const getUserProfile = () => profileEapi.get("/profile");

export const submitEditProfile = userProfile =>
  profileEapi.put("/profile", userProfile);

export const submitChangePassword = payload =>
  profileEapi.put("/profile/password", payload);

export const fetchSecurityQuestions = () =>
  profileEapi.get(`/profile/security-questions`);

///
/// feedback
/// -----
export const postFeedback = body => policyholderEapi.post("/feedback", body);
