import { connect, useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import AccountListContainer from "./containers/AccountListContainer";
import CancellationNotice from "./components/account-summary/CancellationNotice";
import MyProfileContainer from "./containers/MyProfileContainer";
import FeedbackModalContainer from "./feedback/FeedbackModalContainer";
import EditMyProfileContainer from "./containers/EditMyProfileContainer";
import AddAccountContainer from "./containers/AddAccountContainer";
import SideBar from "./components/SideBar";
import BillingStatementsContainer from "./containers/BillingStatementsContainer";
import Footer from "./components/Footer";
import ChangePasswordContainer from "./containers/ChangePasswordContainer";
import AutoIdCardsContainer from "./containers/AutoIdCardsContainer";
import AutomaticPayments from "./components/payments-and-alerts/AutomaticPayments";
import BillingAndAlertsContainer from "./containers/BillingAndAlertsContainer";
import BillingActivityContainer from "./containers/BillingActivityContainer";
import PaymentOptions from "./components/payments-and-alerts/PaymentOptions";
import * as routes from "./constants/routes";
import InstallmentScheduleContainer from "./containers/InstallmentScheduleContainer";
import PolicyBalanceRemainingContainer from "./containers/PolicyBalanceRemainingContainer";
import MyPolicyContainer from "./containers/MyPolicyContainer";
import ClaimsContainer from "./containers/ClaimsContainer";
import CompanyNotificationsContainer from "./containers/CompanyNotificationsContainer";
import { logger } from "./logging";
import {
  SystemsAvailable,
  SystemsUnavailable
} from "./components/system-availability";
import LoadingIndicator, {
  LoadingDocument
} from "./components/loading-indicator";
import NotFound from "./components/errors/NotFound";
import NotImplemented from "./components/errors/NotImplemented";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  sessionSelector,
  userSelector
} from "@ufginsurance/sso-oidc-client-react";
import {
  fetchGuidewireConversions,
  postGWConfirmation,
  fetchCancellationNotice,
  hydrateAddedLinkedAccounts
} from "./actions/accountActions";
import ReviewAccountModal from "./components/modals/ReviewAccountModal";
import { useFullStory } from "./application-hooks/useFullStory";
import { fetchHelpDeskInfo } from "./help-desk/behaviors";
import "@ufginsurance/ui-kit/build/styles/index.css";

const App = props => {
  const {
    isLoading,
    gw_error,
    post_gw_error,
    fetchGuidewireConversions,
    history,
    linkedAccountsAdded,
    hydrateAddedLinkedAccounts
  } = props;

  let activeElement = null;

  const dispatch = useDispatch();
  const helpDeskState = useSelector(state => state?.helpDeskState);

  useEffect(() => {
    if (
      !helpDeskState?.isLoading &&
      !helpDeskState?.error &&
      !helpDeskState?.data
    )
      dispatch(fetchHelpDeskInfo());
  }, [helpDeskState, dispatch]);

  useEffect(() => {
    fetchGuidewireConversions();
    logger.pageview(`${history.location.pathname}${history.location.search}`);
    return function cleanup() {
      historyUnlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!linkedAccountsAdded?.length) return;
    hydrateAddedLinkedAccounts(linkedAccountsAdded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedAccountsAdded]);

  useFullStory(
    props.authenticationToken,
    props.userEmail,
    props.user?.profile?.name,
    props.employeeUsername
  );

  const historyUnlisten = () => {
    history.listen(_ => logger.pageview(`${_.pathname}${_.search}`));
  };

  const handleKeyDown = e => {
    // Scheme to only show the focus outline when the user has used keyboard navigation to focus an element.
    if (e.key !== "Tab") {
      return;
    }

    if (activeElement === document.activeElement) {
      return;
    }

    if (activeElement) {
      activeElement.removeAttribute("data-focused");
    }

    if (document.activeElement) {
      activeElement = document.activeElement;
      activeElement.setAttribute("data-focused", true);
    }
  };

  return (
    <div role="presentation" onKeyDown={handleKeyDown}>
      {isLoading ? (
        <LoadingIndicator />
      ) : gw_error ? null : (
        <ReviewAccountModal
          gw_conversions={props.gw_conversions}
          postGWConfirmation={props.postGWConfirmation}
          post_gw_error={post_gw_error}
        />
      )}
      <Header />
      <main id="content">
        <div className="container" id="body">
          <div className="row">
            <CancellationNotice
              history={props.history}
              accounts={props.accounts || []}
              fetchCancellationNotice={props.fetchCancellationNotice}
            />
            <div className="col-lg-9 col-md-9 col-sm-9 col-sm-push-3">
              <SystemsAvailable>
                <CompanyNotificationsContainer />
              </SystemsAvailable>
              <Switch>
                <Route
                  exact
                  path={routes.SYSTEMS_UNAVAILABLE_ROUTE}
                  component={SystemsUnavailable}
                />
                <Route
                  exact
                  path={routes.ROOT_ROUTE}
                  component={AccountListContainer}
                />
                <Route
                  exact
                  path={routes.ACCOUNTS_ROUTE}
                  component={AccountListContainer}
                />
                <Route
                  path={routes.BILLING_STATEMENTS_ROUTE}
                  component={BillingStatementsContainer}
                />
                <Route
                  path={routes.AUTO_ID_CARDS_ROUTE}
                  component={AutoIdCardsContainer}
                />
                <Route
                  path={routes.POLICY_ROUTE_OPTIONAL_ACCOUNT_PARAM}
                  component={MyPolicyContainer}
                />
                <Route
                  exact
                  path={routes.CLAIMS_ROUTE}
                  component={ClaimsContainer}
                />
                <Route path={routes.PAYMENT_ROUTE} component={NotImplemented} />
                <Route
                  path={routes.BILLING_AND_ALERTS_ROUTE}
                  component={BillingAndAlertsContainer}
                />
                <Route
                  path={routes.MY_PROFILE_ADD_ACCOUNT_ROUTE}
                  component={AddAccountContainer}
                />
                <Route
                  path={routes.MY_PROFILE_CHANGE_PASSWORD_ROUTE}
                  component={ChangePasswordContainer}
                />
                <Route
                  path={routes.EDIT_MY_PROFILE_ROUTE}
                  component={EditMyProfileContainer}
                />
                <Route
                  path={routes.MY_PROFILE_ROUTE}
                  component={MyProfileContainer}
                />
                <Route
                  path={routes.AUTOMATIC_PAYMENTS_ELIGIBLE_ACCOUNTS_ROUTE}
                  component={AutomaticPayments}
                />
                <Route
                  path={routes.INSTALLMENT_ROUTE_OPTIONAL_ACCOUNT_PARAM}
                  component={InstallmentScheduleContainer}
                />
                <Route
                  path={routes.LOADING_DOCUMENT_ROUTE}
                  component={LoadingDocument}
                />
                <Route
                  path={routes.PAYMENT_OPTIONS}
                  component={PaymentOptions}
                />
                <Route
                  path={routes.BILLING_ACTIVITY_OPTIONAL_ACCOUNT_PARAM}
                  component={BillingActivityContainer}
                />
                <Route
                  path={routes.POLICY_BALANCE_REMAINING_ROUTE}
                  component={PolicyBalanceRemainingContainer}
                />
                <Route path={routes.EMPTY_ROUTE} component={NotImplemented} />
                <Route
                  path={routes.ERROR_NOT_FOUND_ROUTE}
                  component={NotFound}
                />
              </Switch>
            </div>
            <FeedbackModalContainer />
            <div className="col-lg-3 col-md-3 col-sm-3 col-sm-pull-9">
              <SideBar />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

App.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  fetchGuidewireConversions: PropTypes.func,
  gw_conversions: PropTypes.object,
  postGWConfirmation: PropTypes.func,
  hydrateAddedLinkedAccounts: PropTypes.func,
  isLoading: PropTypes.bool,
  gw_error: PropTypes.string,
  post_gw_error: PropTypes.string,
  accounts: PropTypes.array,
  fetchCancellationNotice: PropTypes.func,
  authenticationToken: PropTypes.string,
  userEmail: PropTypes.string,
  employeeUsername: PropTypes.string,
  linkedAccountsAdded: PropTypes.array
};

const mapDispatchToProps = {
  fetchGuidewireConversions,
  postGWConfirmation,
  hydrateAddedLinkedAccounts,
  fetchCancellationNotice
};

const mapStateToProps = state => ({
  authenticationToken: sessionSelector.getLegacySSOAuthenticationToken(state),
  userEmail: sessionSelector.getEmail(state),
  employeeUsername: sessionSelector.getEmployeeUserName(state),
  isPolicyholder: sessionSelector.isPolicyholder(state),
  user: userSelector.getOpenIDUser(state),
  ...state.accountData
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
