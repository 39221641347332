import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchAccountData,
  fetchCancellationNotice
} from "../actions/accountActions";
import { fetchAutomaticPayments } from "../actions/automaticPaymentsAction";
import { fetchBillingAndAlerts } from "../actions/billingAndAlertsAction";
import { fetchViewProfile } from "../actions/viewProfileActions";
import LoadingIndicator from "../components/loading-indicator";
import ErrorBoundary from "../components/ErrorBoundary";
import ErrorLevel from "../constants/errorLevel";
import ErrorView from "../components/ErrorView";
import { ErrorCodes } from "../constants/errorCodes";
import { logger } from "../logging";
import AccountList from "../components/account-summary/AccountList";

class AccountListContainer extends Component {
  static propTypes = {
    accountData: PropTypes.object.isRequired,
    billingAndAlertsData: PropTypes.object.isRequired,
    profileData: PropTypes.object.isRequired,
    fetchAccountData: PropTypes.func.isRequired,
    fetchCancellationNotice: PropTypes.func.isRequired,
    fetchAutomaticPayments: PropTypes.func.isRequired,
    fetchBillingAndAlerts: PropTypes.func.isRequired,
    fetchViewProfile: PropTypes.func.isRequired
  };

  componentDidMount() {
    //revaldidate when new account is added
    const addAccountSuccess = new URLSearchParams(window.location.search).get(
      "accountAddedSuccess"
    );
    this.props.fetchAccountData(addAccountSuccess);
    this.props.fetchAutomaticPayments();
    this.props.fetchBillingAndAlerts();
    this.props.fetchViewProfile();
  }
  render() {
    const { accounts, isLoading, error } = this.props.accountData;
    const alertsData = this.props.billingAndAlertsData;
    const addAccountSuccess = new URLSearchParams(window.location.search).get(
      "accountAddedSuccess"
    );
    const linkedAccounts = new URLSearchParams(window.location.search).get(
      "accountsLinked"
    );
    const accountsLinked = linkedAccounts ? linkedAccounts.split(",") : null;

    if (error) {
      const errorMessage = `Error fetching accounts: ${JSON.stringify(error)}`;

      logger.error({
        description: errorMessage,
        fatal: false
      });

      const errStatus =
        error.response && error.response.status
          ? parseInt(error.response.status, 10)
          : ErrorCodes.ServerError;

      return <ErrorView statusCode={errStatus} level={ErrorLevel.danger} />;
    }

    return (
      <ErrorBoundary statusCode={ErrorCodes.ServerError}>
        <div>
          {isLoading || this.props.profileData?.isLoading ? (
            <LoadingIndicator />
          ) : (
            <AccountList
              addAccountSuccess={addAccountSuccess}
              accounts={accounts}
              fetchCancellationNotice={this.props.fetchCancellationNotice}
              billingsAndAlerts={alertsData}
              accountsLinked={accountsLinked}
              userProfile={this.props.profileData?.userProfile}
            />
          )}
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => ({
  accountData: state.accountData,
  billingAndAlertsData: state.billingAndAlertsData,
  profileData: state.profileData
});

const mapDispatchToProps = {
  fetchAccountData,
  fetchAutomaticPayments,
  fetchCancellationNotice,
  fetchBillingAndAlerts,
  fetchViewProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountListContainer);
