import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import orderBy from "lodash.orderby";
import { ContentHeader, SelectNonForm, formatDate } from "@ufginsurance/ui-kit";
import { Translations } from "../components/translations";
import PolicyInfo from "../components/my-policy/PolicyInfo";
import { logger } from "../logging";
import { AnalyticActions } from "../constants/analytics";
import DocumentLink from "../components/account-summary/DocumentLink";
import PolicyActivityContainer from "../components/my-policy/PolicyActivityContainer";
import { fetchPolicyActivity } from "../actions/policyActivityActions";
import {
  fetchMyPoliciesList,
  fetchPolicyDecs
} from "../actions/myPolicyActions";
import LoadingIndicator from "../components/loading-indicator/LoadingIndicator";
import LinesOfBusiness from "../components/my-policy/LinesOfBusiness";
import CustomErrorView from "../components/CustomErrorView";
import HelpDeskMessage from "../help-desk/HelpDeskMessage";

const FIVE_MINUTES = 1000 * 60 * 5;

const enableViewAllFormsButton =
  window.env.REACT_APP_ENABLE_MY_POLICY_VIEW_ALL_FORMS;
class MyPolicyContainer extends Component {
  static propTypes = {
    myPolicyData: PropTypes.object,
    fetchMyPoliciesList: PropTypes.func.isRequired,
    fetchPolicyDecs: PropTypes.func.isRequired,
    fetchPolicyActivity: PropTypes.func.isRequired,
    match: PropTypes.object
  };

  state = {
    selectedPolicyNumber: "",
    fetchPolicyDecsInitially: false,
    enablePolicyDropdown: true
  };

  trackAnalytics = evt => {
    logger.event({
      category: AnalyticActions.policy.view_all_forms,
      action: evt.target.textContent
    });
  };

  componentDidMount() {
    this.props.fetchMyPoliciesList();

    if (this.props.match.params.accountNumber) {
      this.setState({
        selectedPolicyNumber: this.props.match.params.accountNumber,
        fetchPolicyDecsInitially: true
      });
    }
  }

  componentDidUpdate() {
    //TODO: this probably could be accomplished via one of the existing lifecycle methods such as componentWillReceiveProps:
    const { policies, isLoading, coverageOptionsData, error } =
      this.props.myPolicyData;

    if (
      !error &&
      !isLoading &&
      !coverageOptionsData &&
      policies &&
      policies.length === 1
    ) {
      const { company_branch, effective_date } = policies[0].terms[0];
      this.props.fetchPolicyActivity(policies[0].number);
      this.props.fetchPolicyDecs(
        policies[0].number,
        company_branch,
        formatDate(effective_date, "YYYY-MM-DD")
      );
    }

    if (
      !error &&
      this.state.selectedPolicyNumber &&
      this.state.fetchPolicyDecsInitially &&
      !isLoading &&
      !coverageOptionsData &&
      policies &&
      policies.filter(
        policy =>
          policy.origin === "ufg_core" &&
          policy.number === this.state.selectedPolicyNumber
      ).length > 0
    ) {
      this.setState({
        fetchPolicyDecsInitially: false
      });
      const policyTerm = policies.filter(
        policy =>
          policy.origin === "ufg_core" &&
          policy.number === this.state.selectedPolicyNumber
      )[0].terms[0];
      const companyBranch = policyTerm.company_branch;
      const effectiveDate = policyTerm.effective_date;
      this.props.fetchPolicyActivity(this.state.selectedPolicyNumber);
      this.props.fetchPolicyDecs(
        this.state.selectedPolicyNumber,
        companyBranch,
        formatDate(effectiveDate, "YYYY-MM-DD")
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { policies } = props.myPolicyData;
    if (policies && policies.length === 1) {
      if (state.selectedPolicyNumber === policies[0].number) return null;
      return {
        selectedPolicyNumber: policies[0].number,
        enablePolicyDropdown: false
      };
    }
    return null;
  }

  formatPolicyDropDown = policies => {
    return orderBy(policies, p => p.nickname || p.type).map(policy => (
      <option
        key={`${policy.nickname || policy.type} - ${policy.number}`}
        value={policy.number}
      >
        {`${policy.nickname || Translations.policy_types[policy.type]} - ${
          policy.number
        }`}
      </option>
    ));
  };

  isLargePolicy = (policy, coverageOptionsData) => {
    if (!policy || !coverageOptionsData) {
      return false;
    }

    const coverages = coverageOptionsData[policy.number];

    return (
      policy.type.includes("commercial") && coverages && coverages.length > 1
    );
  };

  handleDropDownChange = ({ value }) => {
    return this.setState({ selectedPolicyNumber: value }, () => {
      const { selectedPolicyNumber } = this.state;
      const {
        myPolicyData: { coverageOptionsData, policies },
        fetchPolicyDecs,
        fetchPolicyActivity
      } = this.props;

      if (selectedPolicyNumber) fetchPolicyActivity(selectedPolicyNumber);

      const filteredPolicies =
        policies && policies.length > 0
          ? policies.filter(policy => policy.number === selectedPolicyNumber)
          : null;
      if (
        filteredPolicies &&
        filteredPolicies.length > 0 &&
        filteredPolicies[0].origin === "ufg_core" &&
        ((selectedPolicyNumber && !coverageOptionsData) ||
          (selectedPolicyNumber &&
            coverageOptionsData &&
            !coverageOptionsData[selectedPolicyNumber]))
      ) {
        const { company_branch, effective_date } = filteredPolicies[0].terms[0];
        fetchPolicyDecs(
          selectedPolicyNumber,
          company_branch,
          formatDate(effective_date, "YYYY-MM-DD")
        );
      }
    });
  };

  hasPolicies = policies => policies && policies.length > 0;

  render() {
    const { selectedPolicyNumber } = this.state;
    const { policies, isLoading, coverageOptionsData, error } =
      this.props.myPolicyData;

    if (isLoading) {
      return <LoadingIndicator />;
    }

    const selectedPolicy =
      this.hasPolicies(policies) &&
      policies.find(policy => policy.number === selectedPolicyNumber);

    const showLargePolicyMessage = this.isLargePolicy(
      selectedPolicy,
      coverageOptionsData
    );

    const policyOptions = policies?.length
      ? policies.map(policy => {
          return {
            value: policy.number,
            label: `${
              policy.nickname || Translations.policy_types[policy.type]
            } - ${policy.number}`
          };
        })
      : [];

    return (
      <div>
        <ContentHeader>{Translations.my_policy.title}</ContentHeader>
        {!this.hasPolicies(policies) ? (
          <HelpDeskMessage before="There is currently no Policy Activity available for your policy or account. If you feel this is a technical error," />
        ) : (
          <div>
            <div>
              <SelectNonForm
                id="policyNumber"
                name="policyNumber"
                label="Select Policy"
                placeholder="Select Policy"
                options={policyOptions}
                onChange={this.handleDropDownChange}
                value={selectedPolicyNumber}
                disabled={!this.state.enablePolicyDropdown}
                isClearable={false}
                size="lg"
              />
            </div>
            <div>
              {selectedPolicy && (
                <PolicyInfo
                  key={selectedPolicy.number}
                  policy={selectedPolicy}
                  policyNumber={selectedPolicyNumber}
                />
              )}
            </div>
            {enableViewAllFormsButton.includes("true") &&
              selectedPolicyNumber !== "" &&
              selectedPolicy &&
              selectedPolicy.origin === "ufg_core" && (
                <div className="view-all-forms-group">
                  {showLargePolicyMessage && (
                    <span className="large-policy-message">
                      {
                        Translations.my_policy.lines_of_business.view_all_forms
                          .large_policy_message
                      }
                    </span>
                  )}
                  <div className="view-all-forms-group__button">
                    <DocumentLink
                      color="primary"
                      pdfUri={`${window.env.REACT_APP_POLICYHOLDER_ACCOUNTS_EAPI_MULE_4_URI}/policies/${selectedPolicyNumber}/documents`}
                      filename="My_Policy.pdf"
                      timeout={FIVE_MINUTES}
                      onClick={this.trackAnalytics}
                    >
                      {
                        Translations.my_policy.lines_of_business.view_all_forms
                          .button
                      }
                    </DocumentLink>
                  </div>
                </div>
              )}
            <div className="lines-of-business">
              <div>
                {selectedPolicy &&
                  selectedPolicy.origin === "ufg_core" &&
                  coverageOptionsData &&
                  coverageOptionsData[selectedPolicyNumber] && (
                    <LinesOfBusiness
                      policyNumber={selectedPolicyNumber}
                      coverageOptions={
                        coverageOptionsData[selectedPolicyNumber]
                      }
                      selectedPolicy={selectedPolicy}
                    />
                  )}
                {selectedPolicy &&
                  selectedPolicy.origin === "ufg_core" &&
                  !coverageOptionsData && (
                    <div className="lines-of-business__error">
                      <CustomErrorView
                        error={error}
                        errorLocationInTranslation={
                          Translations.my_policy.lines_of_business.error_codes
                        }
                        handleDeskErrorCodes={["500"]}
                      />
                    </div>
                  )}
              </div>
              <div>
                {selectedPolicy && (
                  <PolicyActivityContainer
                    key={selectedPolicy.number}
                    policyNumber={selectedPolicyNumber}
                    origin={selectedPolicy.origin}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  myPolicyData: {
    policies: state.myPolicyData.policies,
    coverageOptionsData: state.myPolicyData.coverageOptionsData,
    error: state.myPolicyData.error,
    isLoading: state.myPolicyData.isLoading
  }
});

const mapDispatchToProps = {
  fetchMyPoliciesList,
  fetchPolicyActivity,
  fetchPolicyDecs
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPolicyContainer);
