import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import InstallmentSchedule from "../components/installment-schedule/InstallmentSchedule";
import {
  fetchInstallmentScheduleAccountList,
  fetchInstallmentScheduleAccountDetails
} from "../actions/installmentScheduleActions";
import { fetchBillingActivity } from "../actions/billingActivityActions";
import LoadingIndicator from "../components/loading-indicator";
import { Translations } from "../components/translations";
import DocumentLink from "../components/account-summary/DocumentLink";
import * as routes from "../constants/routes";
import PlainErrorView from "../components/PlainErrorView";
import { AnalyticCategories, AnalyticActions } from "../constants/analytics";
import { logger } from "../logging";
import { DirectBillEmailLink } from "../components/email";
import {
  ContentHeader,
  SelectNonForm,
  FlexRow,
  Alert
} from "@ufginsurance/ui-kit";
import SmartPayLink from "../components/SmartPayLink";
import { isPayGo, getPaymentType } from "../utils";

class InstallmentScheduleContainer extends Component {
  static propTypes = {
    installmentScheduleData: PropTypes.object,
    fetchInstallmentScheduleAccountList: PropTypes.func.isRequired,
    fetchInstallmentScheduleAccountDetails: PropTypes.func.isRequired,
    fetchBillingActivity: PropTypes.func.isRequired,
    billingActivityData: PropTypes.object
  };

  state = {
    activeAccount: "",
    paymentType: ""
  };

  componentDidMount() {
    this.props.fetchInstallmentScheduleAccountList();
    this.props.fetchBillingActivity();
  }

  componentDidUpdate(prevProps) {
    const { accountNumbers } = this.props.installmentScheduleData;
    if (accountNumbers !== prevProps.installmentScheduleData.accountNumbers) {
      if (accountNumbers && accountNumbers.length === 1) {
        this.setActiveAccount(accountNumbers[0]);
        const paymentType = getPaymentType(
          this.props.billingActivityData,
          accountNumbers[0]
        );
        this.setState({ paymentType });
      }
    }
  }

  setActiveAccount = newState => {
    const {
      installmentScheduleData: { accountDetails }
    } = this.props;
    return this.setState({ activeAccount: newState }, () => {
      if (
        (!accountDetails || !accountDetails[this.state.activeAccount]) &&
        this.state.activeAccount
      ) {
        return this.props.fetchInstallmentScheduleAccountDetails(
          this.state.activeAccount
        );
      }
    });
  };

  trackAnalytics = details => {
    logger.event({
      category: AnalyticCategories.account,
      action: AnalyticActions.account.fetchInstallmentSchedule,
      label: `Account: ${details}`
    });
  };

  handleChange = ({ value }) => {
    this.setActiveAccount(value);
    this.trackAnalytics(value);
    const paymentType = getPaymentType(this.props.billingActivityData, value);
    this.setState({ paymentType });
  };

  renderAccountDropdown(accountNumbers) {
    if (accountNumbers) {
      const accountOptions = accountNumbers
        .map(num => ({
          value: num,
          label: num
        }))
        .sort((a, b) => b?.value - a?.value);
      return (
        <SelectNonForm
          id="accountNumber"
          name="accountNumber"
          label="Select Account"
          placeholder="Select Account"
          options={accountOptions}
          onChange={this.handleChange}
          value={this.state.activeAccount}
          isClearable={false}
          size="md"
        />
      );
    }
  }

  renderAccount() {
    const {
      installmentScheduleData: { accountDetails }
    } = this.props;
    const { is_current_message, changed_values_message } =
      Translations.installment_schedule;
    if (
      accountDetails &&
      this.state.activeAccount &&
      !isPayGo(this.state.paymentType)
    ) {
      return (
        <div>
          <div className="installment-schedule__info-message">
            {is_current_message}
          </div>
          <InstallmentSchedule account={accountDetails} />
          {changed_values_message}
        </div>
      );
    }
  }

  renderInstallmentSchedule = () => {
    const {
      installmentScheduleData: { accountNumbers, error }
    } = this.props;
    const { activeAccount } = this.state;
    const { print, no_accounts } = Translations.installment_schedule;

    return accountNumbers && accountNumbers.length > 0 ? (
      <div>
        <div className="installment-schedule__sub-header">
          <FlexRow align="justify">
            {this.renderAccountDropdown(accountNumbers)}
            {activeAccount !== "" && !error && (
              <DocumentLink
                className="installment-schedule__print-button"
                color="primary"
                isPostRequest
                postPayload={this.props.installmentScheduleData.accountDetails}
                pdfUri={
                  window.env.REACT_APP_POLICYHOLDER_EAPI_MULE_4_URI +
                  routes.INSTALLMENT_ROUTE_PDF
                }
                filename="Installment_Schedule_Statement.pdf"
                disabled={isPayGo(this.state.paymentType)}
              >
                <span>{print}</span>
              </DocumentLink>
            )}
          </FlexRow>
          {isPayGo(this.state.paymentType) && (
            <Alert dismissible={false} className="wc-smart-pay-alert">
              This Workers&apos; Compensation Policy is managed by SmartPay
              <SmartPayLink />
            </Alert>
          )}
        </div>
        {this.renderAccount()}
        <br />
      </div>
    ) : (
      !error && (
        <div className="no-accounts">
          {Translations.formatString(no_accounts, <DirectBillEmailLink />)}
        </div>
      )
    );
  };

  render() {
    const {
      installment_schedule: { title, info_message }
    } = Translations;
    const { installmentScheduleData, billingActivityData } = this.props;

    if (installmentScheduleData.isLoading || billingActivityData.isLoading) {
      return <LoadingIndicator />;
    }

    return (
      <div>
        <ContentHeader>{title}</ContentHeader>
        <div className="installment-schedule__info-message">
          <i className="fa fa-info-circle" />
          <em>
            {" "}
            {Translations.formatString(info_message, <DirectBillEmailLink />)}
          </em>
        </div>
        {this.renderInstallmentSchedule()}
        {installmentScheduleData.error && (
          <PlainErrorView
            error={installmentScheduleData.error}
            errorLocationInTranslation={
              Translations.installment_schedule.error_messages
            }
            helpDeskErrorCodes={["900", "500", "502"]}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  installmentScheduleData: state.installmentScheduleData,
  billingActivityData: state.billingActivityData
});

const mapDispatchToProps = {
  fetchInstallmentScheduleAccountList,
  fetchInstallmentScheduleAccountDetails,
  fetchBillingActivity
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallmentScheduleContainer);
