import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, Panel, Alert } from "@ufginsurance/ui-kit";
import { PaymentTypes } from "../../constants/enumerations";
import PolicyLink from "./PolicyLink";
import AccountSummary from "./AccountSummary";
import { v4 } from "uuid";

const AccountBilling = ({
  account,
  fetchCancellationNotice,
  sectionGroup,
  paperless
}) => {
  const policies = account.policies.filter(
    p => p.section_group === sectionGroup
  );

  if (!policies?.length) return null;

  return (
    <div className="policy-list-wrapper" key={account.number}>
      {policies.map(policy => {
        const showPendingCancellationNotice =
          policy.status === "pending_cancellation";

        const getCancellationNotice = () => {
          fetchCancellationNotice(policy.number);
        };

        const classes = classNames("policy-details-panel", {
          "bottom-border": ![
            "direct_bill_by_account",
            "direct_bill_by_policy",
            PaymentTypes.payGo
          ].includes(sectionGroup)
        });

        return (
          <Panel
            bgcolor="grey"
            className={classes}
            isOpen={false}
            id={v4()}
            key={policy.number}
            title={
              <div className="policy-header-wrapper">
                {showPendingCancellationNotice && (
                  <Alert
                    type="error"
                    label="Notice!"
                    dismissible={false}
                    className="cancellation-warning"
                  >
                    <span>This policy is pending cancellation.</span>
                    <Button
                      isLink
                      variant="tertiary"
                      onClick={getCancellationNotice}
                      inline
                    >
                      View Cancellation Notice
                    </Button>
                  </Alert>
                )}
                <div
                  key={policy?.number}
                  className="policy-header"
                  data-toggle="collapse"
                  data-target={`#detail-${policy?.number}`}
                  aria-expanded="false"
                >
                  <div className="policy-header__item-group">
                    <div className="policy-list-header__item policy-name">
                      <PolicyLink number={policy?.number} policy={policy} />
                    </div>
                  </div>
                </div>
              </div>
            }
          >
            <AccountSummary
              policy={policy}
              account={account}
              paperless={paperless}
              isInPendingCancel={showPendingCancellationNotice}
            />
          </Panel>
        );
      })}
    </div>
  );
};

AccountBilling.propTypes = {
  account: PropTypes.object.isRequired,
  paperless: PropTypes.object.isRequired,
  fetchCancellationNotice: PropTypes.func.isRequired,
  sectionGroup: PropTypes.string.isRequired
};

export default AccountBilling;
