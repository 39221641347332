import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { orderBy, uniqueId } from "lodash";
import { Translations } from "../translations";
import { fetchForms } from "../../actions/myPolicyFormsActions";
import CustomErrorView from "../CustomErrorView";
import { Button, formatDate, LoadingIndicator } from "@ufginsurance/ui-kit";
import DocumentLink from "../account-summary/DocumentLink";
import DocumentService from "../../services/documentService";
import { getPdf } from "../../services/policyholderService";
import { LOADING_DOCUMENT_ROUTE } from "../../constants/routes";

const addSelectedProperty = forms =>
  forms.map(form => ({ ...form, selected: false }));

const orderForms = forms =>
  ["dec", "typed", "form"]
    .map(formType =>
      orderBy(
        forms.filter(form => form.type === formType),
        form => form.number,
        "asc"
      )
    )
    .reduce((acc, array) => (acc = acc.concat(array)), []);

const propTypes = {
  formData: PropTypes.object,
  policyNumber: PropTypes.string.isRequired,
  coverageOption: PropTypes.string.isRequired,
  fetchForms: PropTypes.func.isRequired,
  selectedPolicy: PropTypes.object
};

class LinesOfBusinessFormsList extends Component {
  state = {
    selectedForms: [],
    selectedViewOption: "current"
  };

  componentDidMount() {
    this.fetchPolicyFormsData();
  }

  componentDidUpdate(prevProps) {
    // Updates selectedForms state after fetchPolicyFormsData fires.
    if (this.props.formData !== prevProps.formData) {
      const { policyNumber, coverageOption } = this.props;
      const policyFormsData = this.props.formData.forms[policyNumber];

      if (!policyFormsData || !policyFormsData[coverageOption]) return;

      this.setState({
        selectedForms: orderForms(
          addSelectedProperty(policyFormsData[coverageOption])
        )
      });
    }
  }

  fetchPolicyFormsData() {
    const { policyNumber, coverageOption, selectedPolicy, formData } =
      this.props;
    let path = "";

    if (selectedPolicy && selectedPolicy.terms) {
      const selectedTerm =
        this.state.selectedViewOption === "current" ? "current" : "renewal";
      const term = selectedPolicy.terms.find(
        item => item.term === selectedTerm
      );

      if (term) {
        path = `?effective_date=${formatDate(
          term.effective_date,
          "YYYY-MM-DD"
        )}&company_branch=${term.company_branch}&term=${selectedTerm}`;
      }
    }

    if (!formData[policyNumber] || !formData[policyNumber][coverageOption]) {
      this.props.fetchForms(policyNumber, coverageOption, path);
    }
  }

  getSelectedFormIds = () =>
    this.state.selectedForms.filter(form => form.selected).map(form => form.id);

  handleGetPdf = (policyNumber, formId) => event => {
    event.stopPropagation();
    const documentRenderer = new DocumentService();
    documentRenderer.initializeRenderer(LOADING_DOCUMENT_ROUTE);

    //HACK: need to make token properties camel case as 'policy-process-api' expects it that way!
    getPdf(
      `${window.env.REACT_APP_POLICYHOLDER_ACCOUNTS_EAPI_MULE_4_URI
      }/policies/${policyNumber}/coverage-form?token=${btoa(
        JSON.stringify({
          drawer: formId.drawer,
          formType: formId.form_type,
          documentType: formId.document_type,
          policyNumber: formId.policy_number,
          package: formId.package,
          referenceNumber: formId.reference_number
        })
      )}`
    ).then(
      response => {
        documentRenderer.saveOrOpenDocument(
          response.data,
          "coverage_options.pdf"
        );
      },
      () => {
        documentRenderer.clearRenderer();
      }
    );
  };

  toggleCheckbox = e => {
    const { checked, value } = e.target;
    const { selectedForms } = this.state;

    this.setState({
      selectedForms: selectedForms.map(form =>
        JSON.stringify(form.id) === value
          ? { ...form, selected: checked }
          : form
      )
    });
  };

  toggleAll = e => {
    const { checked } = e.target;
    const { selectedForms } = this.state;
    this.setState({
      selectedForms: selectedForms.map(form => ({
        ...form,
        selected: form.retrievable ? checked : false
      }))
    });
  };

  renderFormList = forms => {
    const { policyNumber } = this.props;

    return forms.map(form => (
      <tr className="lines-of-business-table__row" key={uniqueId()}>
        <td>
          <input
            checked={form.selected}
            value={JSON.stringify(form.id)}
            onChange={this.toggleCheckbox}
            type="checkbox"
          />
        </td>
        <td>{this.renderFormLink(policyNumber, form, form.name)}</td>
        <td>{form.state}</td>
        <td>{this.renderFormLink(policyNumber, form, form.number)}</td>
        <td>{form.edition_date}</td>
      </tr>
    ));
  };

  renderFilingsList = forms => {
    return forms.map(form => (
      <tr className="lines-of-business-table__row" key={form.id}>
        <td />
        <td>{form.name}</td>
        <td>{form.state}</td>
        <td>{form.number}</td>
        <td>{form.edition_date}</td>
      </tr>
    ));
  };

  renderFormLink = (policyNumber, form, text) => (
    <Link
      to="#"
      onClick={this.handleGetPdf(policyNumber, form.id)}
      color="tertiary"
    >
      <span>{text}</span>
    </Link>
  );

  handleCurrentTermClick = () => {
    this.setState({ selectedViewOption: "current" }, () => {
      this.fetchPolicyFormsData();
    });
  };

  handleRenewalTermClick = () => {
    this.setState({ selectedViewOption: "renewal" }, () => {
      this.fetchPolicyFormsData();
    });
  };

  shouldRenderViewButtonGroup = () => {
    const { selectedPolicy } = this.props;
    if (selectedPolicy && selectedPolicy.terms) {
      const currentTerm = selectedPolicy.terms.find(
        item => item.term === "current"
      );
      const renewalTerm = selectedPolicy.terms.find(
        item => item.term === "renewal"
      );
      return currentTerm && renewalTerm;
    }
    return false;
  };

  render() {
    const { formData, policyNumber, coverageOption } = this.props;
    const { forms, isLoading, error } = formData;
    const { lines_of_business } = Translations.my_policy;

    if (error) {
      return <CustomErrorView error={error} />;
    }
    if (
      !forms[policyNumber] ||
      !forms[policyNumber][coverageOption] ||
      isLoading
    ) {
      return <LoadingIndicator type="spinner" />;
    }

    const allForms = this.state.selectedForms;
    const retrievableForms = allForms.filter(form => form.retrievable === true);
    const nonRetrievableForms = allForms.filter(
      form => form.retrievable === false
    );

    return (
      <div>
        {this.shouldRenderViewButtonGroup() && (
          <div className="lines-of-business__button-group">
            <Button
              onClick={this.handleCurrentTermClick}
              variant={
                this.state.selectedViewOption === "current"
                  ? "primary"
                  : "secondary"
              }
            >
              Current
            </Button>
            <Button
              onClick={this.handleRenewalTermClick}
              variant={
                this.state.selectedViewOption === "renewal"
                  ? "primary"
                  : "secondary"
              }
            >
              Renewal
            </Button>
          </div>
        )}
        <div className="lines-of-business__forms-list-info">
          {Translations.my_policy.select_forms_help_message}
        </div>
        <div className="lines-of-business-table__wrapper">
          <table className="lines-of-business-table">
            <thead className="lines-of-business-table__head">
              <tr className="lines-of-business-table__row">
                <th className="lines-of-business-table__checkbox">
                  <input onChange={this.toggleAll} type="checkbox" />
                </th>
                <th>{lines_of_business.form_name}</th>
                <th>{lines_of_business.state}</th>
                <th>{lines_of_business.number}</th>
                <th>{lines_of_business.edition_date}</th>
              </tr>
            </thead>
            <tbody>
              {this.renderFormList(retrievableForms)}
              <tr>
                <td colSpan="5">
                  <div className="lines-of-business__forms-button">
                    <DocumentLink
                      isPostRequest
                      disabled={this.state.selectedForms.length === 0}
                      color="primary"
                      pdfUri={`${window.env.REACT_APP_POLICYHOLDER_ACCOUNTS_EAPI_MULE_4_URI}/policies/${policyNumber}/coverage-form`}
                      postPayload={this.getSelectedFormIds()}
                      filename="My_Policy.pdf"
                      onClick={this.trackAnalytics}
                    >
                      <span>{lines_of_business.view_forms}</span>
                    </DocumentLink>
                  </div>
                </td>
              </tr>
              {nonRetrievableForms && nonRetrievableForms.length > 0 && (
                <tr className="lines-of-business-table__row lines-of-business-table__subhead">
                  <td colSpan="5">
                    <span>Filings</span>
                  </td>
                </tr>
              )}
              {nonRetrievableForms &&
                nonRetrievableForms.length > 0 &&
                this.renderFilingsList(nonRetrievableForms)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formData: state.myPolicyFormsData
});

const mapDispatchToProps = {
  fetchForms
};

LinesOfBusinessFormsList.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinesOfBusinessFormsList);
