import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Translations } from "../translations";
import { POLICY_ROUTE } from "../../constants/routes";
import { isPayGo } from "../../utils";

const policyTypeMessage = (number, policy) => {
  if (isPayGo(policy?.billing?.payment_type))
    return "Workers' Comp (Pay as you go)";
  const types = Translations.policy_types;
  return !types.hasOwnProperty(policy.type) ? number : types[policy.type];
};

const PolicyLink = ({ number, policy }) => {
  return (
    <Link to={`${POLICY_ROUTE}/${number}`} className="text-dark-blue">
      {!!policy.nickname ? policy.nickname : policyTypeMessage(number, policy)}
    </Link>
  );
};

PolicyLink.propTypes = {
  number: PropTypes.string.isRequired,
  policy: PropTypes.object.isRequired
};

export default PolicyLink;
