import "./polyfills";
import "url-polyfill";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import AppLoader from "./AppLoader";
import { unregister } from "./registerServiceWorker";
import initializeStore from "./store";
import { logger } from "./logging";
import ScrollToTop from "./components/ScrollToTop";
import {
  ssoClient,
  SSOValidationContainer,
  configuration
} from "@ufginsurance/sso-oidc-client-react";

// Unregister the service worker from create-react-app
unregister();

logger.initialize(
  window.env.REACT_APP_GA_TRACKING_ID,
  window.env.REACT_APP_GA_DEBUG,
  window.env.REACT_APP_ENABLE_DIAGNOSTICS
);

const store = initializeStore();

if (
  window.decodeURIComponent(window.location.pathname) === "/force-logout" ||
  window.decodeURIComponent(window.location.pathname) === "//force-logout"
) {
  const url = `${window.env.REACT_APP_SSO_BASEURL}/Login?ApplicationID=${window.env.REACT_APP_SSO_CLIENT_ID}&ReturnUrl=%2Fsso&RedirectUrl=%2F`;
  window.location.replace(url);
} else {
  // Always called on resolve of handler.
  const defaultRender = () => {
    const container = document.getElementById("root");
    const root = createRoot(container);
    root.render(
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <SSOValidationContainer
              store={store}
              userManager={ssoClient.userManager}
              clientType={configuration.CLIENT_TYPE_POLICYHOLDER}
            >
              <AppLoader />
            </SSOValidationContainer>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  };

  (() => defaultRender())();
}
