import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";
import uniqBy from "lodash.uniqby";
import PolicyBalanceRemaining from "../components/billing-activity/PolicyBalanceRemaining";
import LoadingIndicator from "../components/loading-indicator/LoadingIndicator";
import { fetchPolicyBalanceRemainingDetails } from "../actions/policyBalanceRemainingActions";
import { fetchBillingActivity } from "../actions/billingActivityActions";
import DocumentLink from "../components/account-summary/DocumentLink";
import * as routes from "../constants/routes";
import { Translations } from "../components/translations";
import { AnalyticCategories, AnalyticActions } from "../constants/analytics";
import { logger } from "../logging";
import { DirectBillEmailLink } from "../components/email";
import {
  ContentHeader,
  SelectNonForm,
  FlexRow,
  Alert
} from "@ufginsurance/ui-kit";
import SmartPayLink from "../components/SmartPayLink";
import { isPayGo, getPaymentType } from "../utils";

class PolicyBalanceRemainingContainer extends Component {
  static propTypes = {
    billingActivityData: PropTypes.object,
    policyBalanceRemainingData: PropTypes.object,
    fetchPolicyBalanceRemainingDetails: PropTypes.func.isRequired,
    fetchBillingActivity: PropTypes.func.isRequired,
    match: PropTypes.object,
    accountsList: PropTypes.object
  };

  state = {
    selectedAccount: "",
    selectedPolicy: "",
    paymentType: ""
  };

  componentDidMount() {
    const {
      fetchBillingActivity,
      match: { params }
    } = this.props;
    if (params.account) {
      this.setActiveAccount(params.account);
    }

    return fetchBillingActivity().then(() => {
      const { accounts } = this.props.billingActivityData;
      const accountList = accounts?.map(account => account.number) ?? [];
      if (accounts && accounts?.length === 1) {
        this.setActiveAccount(accountList[0]);
      }
    });
  }

  getPolicyOptions = () => {
    const { selectedAccount } = this.state;
    const { accounts } = this.props.billingActivityData;

    const filteredAccounts =
      selectedAccount && accounts
        ? accounts?.filter(
            a => a.number === selectedAccount && a.has_direct_bill_policies
          ) ?? []
        : [];

    if (filteredAccounts.length === 0) return [];
    const policyOptions = filteredAccounts[0].policies
      .filter(
        policy =>
          policy.section_group.includes("direct_bill_by_account") ||
          policy.section_group.includes("direct_bill_by_policy")
      )
      .map(policy => {
        if (!policy.nickname && policy.type === "unknown") {
          return { value: policy.number, label: policy.number };
        }
        return {
          value: policy.number,
          label: `${
            policy.nickname || Translations.policy_types[policy.type]
          } - ${policy.number}`
        };
      });

    return uniqBy(orderBy(policyOptions, "value", "desc"), "value");
  };

  trackAnalytics = details => {
    logger.event({
      category: AnalyticCategories.account,
      action: AnalyticActions.account.fetchPolicyBalanceRemaining,
      label: `Account: ${details}`
    });
  };

  setActiveAccount = selectedAccount => {
    const { fetchPolicyBalanceRemainingDetails } = this.props;
    this.setState({ selectedAccount, selectedPolicy: "" });
    fetchPolicyBalanceRemainingDetails(selectedAccount);
  };

  setActivePolicy = selectedPolicy => this.setState({ selectedPolicy });

  handleAccountChange = ({ value }) => {
    this.trackAnalytics(value);
    this.setActiveAccount(value);
    const paymentType = getPaymentType(this.props.billingActivityData, value);
    this.setState({ paymentType });
  };

  handlePolicy = ({ value }) => {
    this.setActivePolicy(value);
  };

  renderAccount(selectedAccount) {
    const {
      policyBalanceRemainingData: { accountData }
    } = this.props;

    if (
      (!!selectedAccount && (!accountData || !accountData[selectedAccount])) ||
      this.props.policyBalanceRemainingData.error
    ) {
      return (
        <div className="policy-balance-remaining__no-accounts">
          {Translations.formatString(
            Translations.billing_activity.policy_balance_remaining_no_accounts,
            <DirectBillEmailLink />
          )}
        </div>
      );
    }

    if (
      selectedAccount &&
      accountData &&
      accountData[selectedAccount] &&
      accountData[selectedAccount].length === 0
    ) {
      return (
        <div className="policy-balance-remaining__no-accounts">
          {Translations.formatString(
            Translations.billing_activity.policy_balance_remaining_no_accounts,
            <DirectBillEmailLink />
          )}
        </div>
      );
    }

    if (
      accountData &&
      selectedAccount &&
      accountData[selectedAccount] &&
      accountData[selectedAccount].length > 0 &&
      !isPayGo(this.state.paymentType)
    ) {
      const { accountsList } = this.props;
      const currentAccount =
        accountsList && Array.isArray(accountsList.accounts)
          ? accountsList.accounts?.find(o => o.number === selectedAccount)
          : undefined;
      const is_service_center =
        currentAccount && currentAccount.is_service_center;
      return (
        <PolicyBalanceRemaining
          origin={accountData[selectedAccount][0].origin}
          is_service_center={!!is_service_center}
          policies={accountData[selectedAccount]}
          selectedPolicy={this.state.selectedPolicy}
        />
      );
    }
  }

  renderSection = (accounts, selectedAccount) => {
    const {
      policyBalanceRemainingData: { accountData }
    } = this.props;

    if (accounts.length === 1 && !accountData) {
      return null;
    }

    const policyOptions = this.getPolicyOptions();

    const hasPoliciesInFinalAudit =
      selectedAccount &&
      Array.isArray(accountData[selectedAccount]) &&
      accountData[selectedAccount].find(_ => _.final_audit === true);

    const accountOptions =
      accounts
        ?.map(account => ({
          value: account,
          label: account
        }))
        ?.sort((a, b) => b.value - a.value) ?? [];

    return (
      <div className="policy-balance-remaining__options">
        <div className="policy-balance-remaining__drop-downs">
          <FlexRow>
            <SelectNonForm
              id="accountNumber"
              name="accountNumber"
              label="Select Account"
              placeholder="Select Account"
              options={accountOptions}
              onChange={this.handleAccountChange}
              value={this.state?.selectedAccount || ""}
              isClearable={false}
              size="md"
            />
            <SelectNonForm
              id="policyNumber"
              name="policyNumber"
              label="Select Policy"
              placeholder="Select Policy"
              options={policyOptions}
              onChange={this.handlePolicy}
              value={
                policyOptions?.length === 1
                  ? policyOptions[0]?.value || ""
                  : this.state?.selectedPolicy || ""
              }
              isClearable={false}
              disabled={!selectedAccount || policyOptions?.length === 1}
              size="lg"
            />
            {selectedAccount &&
              !hasPoliciesInFinalAudit &&
              policyOptions.length > 0 &&
              !this.props.policyBalanceRemainingData.error && (
                <div className="policy-balance-remaining__print align-right">
                  <DocumentLink
                    isPostRequest
                    className="installment-schedule__print-button"
                    color="primary"
                    pdfUri={`${window.env.REACT_APP_POLICYHOLDER_EAPI_MULE_4_URI}${routes.POLICY_BALANCE_REMAINING_ROUTE_PDF}/${selectedAccount}`}
                    postPayload={
                      this.props.policyBalanceRemainingData.accountData[
                        selectedAccount
                      ]
                    }
                    filename="Billing_Statement.pdf"
                  >
                    <span>PRINT</span>
                  </DocumentLink>
                </div>
              )}
          </FlexRow>
          {isPayGo(this.state.paymentType) && (
            <Alert dismissible={false} className="wc-smart-pay-alert">
              This Workers&apos; Compensation Policy is managed by SmartPay
              <SmartPayLink />
            </Alert>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      billingActivityData,
      billingActivityData: { accounts },
      policyBalanceRemainingData
    } = this.props;
    const accountList = accounts?.map(account => account.number) || [];
    const { selectedAccount } = this.state;

    if (billingActivityData.isLoading || policyBalanceRemainingData.isLoading) {
      return <LoadingIndicator />;
    }

    if (
      billingActivityData &&
      billingActivityData.error &&
      billingActivityData.error.response &&
      billingActivityData.error.response.status === 404
    ) {
      return (
        <div className="policy-balance-remaining">
          <h1>{Translations.billing_activity.policy_balance_remaining}</h1>
          <div className="policy-balance-remaining__no-accounts">
            {Translations.formatString(
              Translations.billing_activity.error_messages[308]
            )}
          </div>
        </div>
      );
    }

    if (accountList && accountList.length === 0) {
      return (
        <div className="policy-balance-remaining">
          <h1>{Translations.billing_activity.policy_balance_remaining}</h1>
          <div className="policy-balance-remaining__no-accounts">
            {Translations.formatString(
              Translations.billing_activity
                .policy_balance_remaining_no_accounts,
              <DirectBillEmailLink />
            )}
          </div>
        </div>
      );
    }

    return accounts ? (
      <div>
        <ContentHeader>
          {Translations.billing_activity.policy_balance_remaining}
        </ContentHeader>
        <div className="policy-balance-remaining">
          {this.renderSection(accountList, selectedAccount)}
        </div>
        {this.renderAccount(this.state.selectedAccount)}
      </div>
    ) : (
      <LoadingIndicator />
    );
  }
}
const mapStateToProps = state => ({
  billingActivityData: state.billingActivityData,
  policyBalanceRemainingData: state.policyBalanceRemainingData,
  accountsList: state.accountData
});

const mapDispatchToProps = {
  fetchBillingActivity,
  fetchPolicyBalanceRemainingDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyBalanceRemainingContainer);
