import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button } from "@ufginsurance/ui-kit";
import InfoModal from "./InfoModal";
import { Translations } from "../translations";
import ErrorLevel from "../../constants/errorLevel";
import "./_ReviewAccountModal.scss";
import HelpDeskMessage from "../../help-desk/HelpDeskMessage";

export default function ReviewAccountModal({
  gw_conversions,
  postGWConfirmation,
  post_gw_error
}) {
  const [showModal, setShowModal] = useState(true);

  const hideModal = () => {
    setShowModal(false);
  };

  const has_gw_account_number =
    gw_conversions && gw_conversions.find(o => o.gw_conversion_account_number);

  if (!(has_gw_account_number && showModal)) return null;

  return (
    <InfoModal
      hideModal={hideModal}
      hideCross
      title={<b>{Translations.account_summary.GW_conversions.Title}</b>}
    >
      <div className="review_account_modal_content">
        {post_gw_error && (
          <Alert type={ErrorLevel.danger}>
            {Translations.account_summary.GW_conversions.failed_post_api_error}
          </Alert>
        )}
        <p>{Translations.account_summary.GW_conversions.header_text}</p>
        <ul>
          <li>
            {Translations.account_summary.GW_conversions.Paperless_billing}
          </li>
          <li>{Translations.account_summary.GW_conversions.Payment_alerts}</li>
          <li>
            {Translations.account_summary.GW_conversions.Automatic_payments}
          </li>
        </ul>
        <p>{Translations.account_summary.GW_conversions.I_accept_text}</p>
        <p>
          <HelpDeskMessage before="If you have any questions," />
        </p>
        <div className="row">
          <div className="col-xs-12 col-sm-3 col-sm-offset-6">
            <Button
              type="reset"
              variant="secondary"
              onClick={() => hideModal()}
            >
              {Translations.buttons.cancel}
            </Button>
          </div>
          <div className="col-xs-12 col-sm-3">
            <Button
              type="submit"
              variant="primary"
              onClick={() => {
                postGWConfirmation();
                hideModal();
              }}
            >
              {Translations.account_summary.GW_conversions.I_understand}
            </Button>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}

ReviewAccountModal.propTypes = {
  gw_conversions: PropTypes.object,
  postGWConfirmation: PropTypes.func,
  post_gw_error: PropTypes.string
};
